@font-face {
  font-family: 'DiodrumSemiBold';
  src: url("https://cdn.guidion.com/selfservice/ziggo/fonts/diodrum-semibold.eot");
  src: url("https://cdn.guidion.com/selfservice/ziggo/fonts/diodrum-semibold.woff") format("woff");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'DiodrumRegular';
  src: url("https://cdn.guidion.com/selfservice/ziggo/fonts/diodrum-regular.eot");
  src: url("https://cdn.guidion.com/selfservice/ziggo/fonts/diodrum-regular.woff") format("woff");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'museo900';
  src: url("https://cdn.guidion.com/selfservice/ziggo/fonts/museosans_900-webfont.eot");
  src: url("https://cdn.guidion.com/selfservice/ziggo/fonts/museosans_900-webfont.eot?#iefix") format("embedded-opentype"), url("https://cdn.guidion.com/selfservice/ziggo/fonts/museosans_900-webfont.woff") format("woff"), url("https://cdn.guidion.com/selfservice/ziggo/fonts/museosans_900-webfont.ttf") format("truetype");
  font-style: normal; }

* {
  color: #262727;
  font-family: 'Open Sans', 'Verdana', Arial, Helvetica, sans-serif;
  font-weight: 400;
  margin: 0; }

body:not(.inIframe) {
  background: rgba(0, 0, 0, 0.03); }
  @media only screen and (min-width: 460px) {
    body:not(.inIframe) {
      padding-bottom: 32px; } }

body.inIframe form {
  max-width: 760px; }

h1 {
  color: #F48C00;
  font-family: 'DiodrumSemiBold', 'museo900', Helvetica, Arial, sans-serif;
  font-size: 28px;
  line-height: 120%; }
  @media only screen and (min-width: 460px) {
    h1 {
      font-size: 36px; } }

h2 {
  font-family: 'DiodrumRegular', Helvetica, Arial, sans-serif;
  font-size: 18px;
  line-height: 160%;
  margin: 4px 0; }
  @media only screen and (min-width: 460px) {
    h2 {
      font-size: 22px; } }

img {
  margin-bottom: 24px; }
  @media only screen and (min-width: 460px) {
    img {
      margin-bottom: 36px; } }

a {
  color: #F48C00;
  text-decoration: none; }

p {
  display: inline-block;
  font-size: 14px;
  line-height: 175%;
  margin: 16px 0; }
  @media only screen and (min-width: 460px) {
    p {
      font-size: 16px; } }

ul {
  overflow: hidden; }

li {
  color: #c02;
  font-size: 14px;
  margin-bottom: 8px; }

@media only screen and (min-width: 460px) {
  form {
    margin-top: 16px; } }

div {
  position: relative; }

input::-webkit-input-placeholder {
  color: #e5e5e5; }

input::-moz-placeholder {
  color: #e5e5e5; }

input:-ms-input-placeholder {
  color: #e5e5e5; }

input:-moz-placeholder {
  color: #e5e5e5; }

input::placeholder {
  color: #e5e5e5; }
